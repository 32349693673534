import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --background-color: ${(props) => props.backgroundColor || '#E5E5E5'};
    --font-color: ${(props) => props.fontColor || '#2E2E2E'};
    --foreground-color: ${(props) => props.foregroundColor || '#796F00'};
    --card-color: ${(props) => props.cardColor || '#B9B695'};
    --dark-grey: #C4C4C4;
    --white: #FFF;
  }
  
  html {
    font-size: 16px;
    letter-spacing: 1px;
    color: var(--font-color);
  }
  
  p {
    margin-top: 1rem;
  }
  
  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  
  img {
    max-width: 100%;
  }
  
  .gallery {
    margin: 0 auto;
    width: 90%;
  }
`;

export default GlobalStyles;
