import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const SecondaryLinkStyles = styled(Link)`
  border: 1px solid var(--foreground-color);
  color: var(--font-color);
  padding: 0.75rem 0;
  width: 10rem;
  text-decoration: none;
  border-radius: 15px;
  font-family: 'Lato';
  text-align: center;
  font-weight: bold;
`;

export default function SecondaryLink({ linkText }) {
  return <SecondaryLinkStyles to="/contact/">{linkText}</SecondaryLinkStyles>;
}
