import { createGlobalStyle } from 'styled-components';

import lato from '../assets/fonts/lato-v17-latin-regular.woff';
import amatic from '../assets/fonts/amatic-sc-v16-latin-regular.woff';

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(${lato});
  }
  
  @font-face {
    font-family: 'Amatic SC'; 
    font-style: normal;
    font-weight: 400;
    src: url(${amatic});
  }
  
  html {
    color: var(--black);
    font-family: 'Lato', sans-serif;
    line-height: 1.6rem;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-weight: bold;
    margin: 0;
  }
`;

export default Typography;
