import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, Link, graphql } from 'gatsby';
import Nav from './Nav';

const HeaderStyles = styled.header`
  display: grid;
  justify-content: center;
  grid-template-columns: 1;
  place-items: center;
  width: 100%;
  background-color: var(--background-color);
  font-family: 'Amatic SC';
  line-height: normal;

  #logo {
    font-size: 5rem;
    color: var(--foreground-color);
    padding-top: 2rem;
    text-align: center;
    letter-spacing: 5px;
    a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  #tagline {
    font-size: 2rem;
    text-align: center;
  }
`;

export default function Header() {
  const { header } = useStaticQuery(graphql`
    query {
      header: sanitySiteSettings {
        logoHeading
        strapline
      }
    }
  `);
  return (
    <HeaderStyles>
      <div id="logo">
        <Link to="/">{header?.logoHeading || 'Edinburgh Art'}</Link>
      </div>
      <div id="tagline">
        {header?.strapline || 'Local Art, Worldwide Appeal'}
      </div>
      <Nav />
    </HeaderStyles>
  );
}
