import React from 'react';
import styled from 'styled-components';

const PrimaryLinkStyles = styled.a`
  background-color: var(--foreground-color);
  color: var(--white);
  padding: 0.75rem 4rem;
  text-decoration: none;
  border-radius: 10px;
  font-family: 'Lato';
  text-align: center;
  font-weight: bold;
`;

export default function PrimaryLink({ linkText, onClick, href }) {
  return (
    <PrimaryLinkStyles href={href} onClick={onClick}>
      {linkText}
    </PrimaryLinkStyles>
  );
}
