import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { useStaticQuery, graphql } from 'gatsby';

const ContainerStyles = styled.main`
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1100px;
`;

const HeaderOverlap = styled.div`
  background: var(--background-color);
  position: absolute;
  width: 100%;
  height: 9rem;
  left: 0;
  z-index: -1;
`;

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query {
      siteSettings: sanitySiteSettings {
        backgroundColor {
          hex
        }
        cardColor {
          hex
        }
        fontColor {
          hex
        }
        foregroundColor {
          hex
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles
        backgroundColor={data.siteSettings?.backgroundColor?.hex || null}
        foregroundColor={data.siteSettings?.foregroundColor?.hex || null}
        fontColor={data.siteSettings?.fontColor?.hex || null}
        cardColor={data.siteSettings?.cardColor?.hex || null}
      />
      <Typography />
      <Header />
      <HeaderOverlap />
      <ContainerStyles>{children}</ContainerStyles>
      <Footer />
    </>
  );
}
