import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import PrimaryLink from './PrimaryLink';
import SecondaryLink from './SecondaryLink';

const NavStyles = styled.nav`
  display: flex;
  gap: 4rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
`;

export default function Nav() {
  const { navigation } = useStaticQuery(graphql`
    query {
      navigation: sanitySiteSettings {
        storeUrl
      }
    }
  `);
  return (
    <NavStyles>
      {navigation?.storeUrl && (
        <PrimaryLink linkText="Store" href={navigation?.storeUrl} />
      )}
      <SecondaryLink linkText="Contact me" />
    </NavStyles>
  );
}
