import React from 'react';
import styled from 'styled-components';
import {
  RiTwitterLine,
  RiLinkedinLine,
  RiInstagramLine,
  RiFacebookLine,
} from 'react-icons/ri';
import { IconContext } from 'react-icons';
import { useStaticQuery, graphql } from 'gatsby';

const FooterStyles = styled.footer`
  border-top: 1px solid #000;
  display: grid;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  margin-top: 2rem;

  p {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  a {
    transition: transform 0.2s;
  }

  a:hover {
    transform: scale(1.5);
  }
`;

const SocialLinkStyles = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  gap: 6rem;
  flex-wrap: wrap;

  @media screen and (max-width: 515px) {
    gap: 2rem;
  } ;
`;

export default function Footer() {
  const { footer } = useStaticQuery(graphql`
    query {
      footer: sanitySiteSettings {
        instagram
        facebook
        twitter
        linkedin
        copyrightHolder
      }
    }
  `);
  return (
    <FooterStyles>
      <SocialLinkStyles>
        <IconContext.Provider
          value={{ color: 'var(--foreground-color)', size: '2rem' }}
        >
          {footer?.instagram && (
            <a href={footer.instagram}>
              <RiInstagramLine />
            </a>
          )}
          {footer?.facebook && (
            <a href={footer.facebook}>
              <RiFacebookLine />
            </a>
          )}
          {footer?.twitter && (
            <a href={footer.twitter}>
              <RiTwitterLine />
            </a>
          )}
          {footer?.linkedin && (
            <a href={footer.linkedin}>
              <RiLinkedinLine />
            </a>
          )}
        </IconContext.Provider>
      </SocialLinkStyles>
      <p>
        All images on this site are the copyright of{' '}
        {footer?.copyrightHolder || 'Copyright Holder'} and must not be
        reproduced in any form.
      </p>
      <p>
        &copy; {footer?.copyrightHolder || 'Copyright Holder'}{' '}
        {new Date().getFullYear()}
      </p>
    </FooterStyles>
  );
}
